exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-contentful-category-name-js": () => import("./../../../src/pages/categories/{ContentfulCategory.name}.js" /* webpackChunkName: "component---src-pages-categories-contentful-category-name-js" */),
  "component---src-pages-contentful-person-name-js": () => import("./../../../src/pages/{ContentfulPerson.name}.js" /* webpackChunkName: "component---src-pages-contentful-person-name-js" */),
  "component---src-pages-contentful-resource-title-js": () => import("./../../../src/pages/{ContentfulResource.title}.js" /* webpackChunkName: "component---src-pages-contentful-resource-title-js" */),
  "component---src-pages-contentful-service-title-js": () => import("./../../../src/pages/{ContentfulService.title}.js" /* webpackChunkName: "component---src-pages-contentful-service-title-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-workplace-investigations-workshop-js": () => import("./../../../src/pages/workplace-investigations-workshop.js" /* webpackChunkName: "component---src-pages-workplace-investigations-workshop-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */)
}

